import React from "react";
import useImageUrl from "./useImageUrl";

const ImageComponent = ({ imagePath }) => {
  const imageUrl = useImageUrl(imagePath);
  // console.log(imageUrl, "called");
  return (
    <img src={imageUrl} alt="" />
    // <>
    //   {imageUrl ? (
    //   ) : (
    //     <img src={require("../../../assets/vectorProfile.png")} alt="" />
    //   )}
    // </>
  );
};

export default ImageComponent;
